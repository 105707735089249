<script>
	import GlobalVue from '../../helper/Global.vue'
	import Gen from '../../helper/Gen'
	import swal from 'sweetalert'

	export default {
		extends: GlobalVue,
		data() {
			return {
				input: {},
			}
		},
		computed: {},
		mounted() {
			this.$emit("ready", this)
		},
		methods: {
			open(params) {
				global.Modal = this
				$.magnificPopup.open({
					items: {
						src: "#ModalLegalAge"
					},
					type: 'inline',
					closeOnContentClick: false,
					closeBtnInside: false,
					closeOnBgClick: false,
					enableEscapeKey: false,
					fiexContentPos: true,
					showCloseBtn: false,
					overflowY: 'scroll',
				})
				if(params){
					this.$set(this.input, 'month', params.month)
					this.$set(this.input, 'year', params.year)
				}
			},
			onSearch() {

			},
			submitAge(){
				if(this.input.year == '-') return swal("input your age","","warning")
				if(this.input.month == '-') return swal("input your age","","warning")
				let birthday = this.input.year+'-'+this.input.month+'-'+this.input.day
				let x = this.sumAge(this.input.month,"01",this.input.year)
				if(x < 21) return swal("your age under rule","","warning")
				this.$emit("callback", x)
				Gen.putStorage("legal_age", x)
				Gen.putStorage("time_legal", moment().format('YYYY-MM-DD HH:mm:ss'))
				$.magnificPopup.close()
			},
			sumAge(birth_month,birth_day,birth_year){
				let today_date = new Date();
				let today_year = today_date.getFullYear();
				let today_month = today_date.getMonth();
				let today_day = today_date.getDate();
				let age = today_year - birth_year;

				if ( today_month < (birth_month - 1))
				{
					age--;
				}
				if (((birth_month - 1) == today_month) && (today_day < birth_day))
				{
					age--;
				}
				return age;
			}
		},
		watch: {}
	}
</script>

<template>
	<!-- Pilih Desain-->
	<div class="modal1 mfp-hide subscribe-widget" id="ModalLegalAge">
		<div class="block divcenter bg_modal" style=" max-width: 800px;">
			<div class="pad_80">
				<div class="row justify-content-around">
					<div class="col-md-10">
						<div class="wrap_logo_modal">
							<img :src="assets('fo_images','logo_dimatique.png')" />
						</div>
						<div class="age_inform">
							<p>This site contains promotion of alcohol and should not be viewed by minor.</p>
							<h3>Confirm Your Age</h3>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group select_custom">
							<select v-model="input.month" id="template-contactform-service" name="template-contactform-service"
								class="sm-form-control  frm_custom valid">
								<option style="font-family: Verdana" value="-">Month</option>
								<option style="font-family: Verdana" value="01">January</option>
								<option style="font-family: Verdana" value="02">February</option>
								<option style="font-family: Verdana" value="03">March</option>
								<option style="font-family: Verdana" value="04">April</option>
								<option style="font-family: Verdana" value="05">May</option>
								<option style="font-family: Verdana" value="06">June</option>
								<option style="font-family: Verdana" value="07">July</option>
								<option style="font-family: Verdana" value="08">August</option>
								<option style="font-family: Verdana" value="09">September</option>
								<option style="font-family: Verdana" value="00">October</option>
								<option style="font-family: Verdana" value="11">November</option>
								<option style="font-family: Verdana" value="12">December</option>
							</select>
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group select_custom">
							<select v-model="input.year" id="template-contactform-service" name="template-contactform-service"
								class="sm-form-control frm_custom valid">
								<option value="-" selected>Year</option>
								<option style="font-family: Verdana" v-for="(v,k) in $root.dataYear" :key="k" :value="v">{{v}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 mt-4">
						<p class="mb-0 text-center"><a href="javascript:;" @click="submitAge" class="btn_line">Confirm</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>